import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { getDynamicTeamImage } from '@/helpers/getDynamicImage';
import useIsAboveBreakpoint from '@/helpers/useIsAboveBreakpoint';
import * as teamManagementService from '@/services/teamManagement.service';
import useLayoutStore from '@/stores/layout/layoutStore';
import useTeamManagementStore from '@/stores/teamManagement/teamManagementStore';
import { useUserStore } from '@/stores/user/userStore';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';

import DModalCreateNew from '../DModalCreateNew';
import DNotificationHeader from '../DNotificationHeader';
import AIVoiceIcon from '../Global/AIVoiceIcon';
import DButtonIcon from '../Global/DButtonIcon';
import DFullLogo from '../Global/DLogo/DFullLogo';
import DNavLink from '../Global/DNavLink';
import ActivityIcon from '../Global/Icons/ActivityIcon';
import AddIcon from '../Global/Icons/AddIcon';
import AiAvatarIcon from '../Global/Icons/AiAvatarIcon';
import AiChatbotIcon from '../Global/Icons/AiChatbotIcon';
import BookIcon from '../Global/Icons/BookIcon';
import ChevronDownIcon from '../Global/Icons/ChevronDownIcon';
import ClockIcon from '../Global/Icons/ClockIcon';
import CloseIcon from '../Global/Icons/CloseIcon';
import CloseNavIcon from '../Global/Icons/CloseNavIcon';
import ExpandIcon from '../Global/Icons/ExpandIcon';
import GraphIcon from '../Global/Icons/GraphIcon';
import LeaveTeamIcon from '../Global/Icons/LeaveTeamIcon';
import LiveAgentIcon from '../Global/Icons/LiveAgentIcon';
import NotificationIcon from '../Global/Icons/NotificationIcon';
import OpenNavIcon from '../Global/Icons/OpenNavIcon';
import OptionsHorizontalIcon from '../Global/Icons/OptionsHorizontalIcon';
import HeaderAccountMenu from '../HeaderAccountMenu';
import './index.css';
import PhoneIcon from '../Global/Icons/PhoneIcon';
import DModalBetaVoice from '../DModalBetaVoice';
import DTransition from '../Global/DTransition';
import DCircularChart from '../Global/DCircularChart';
import DModalBetaAvatar from '../DModalBetaAvatar';
import featureCheck from '@/helpers/tier/featureCheck';
import useToast from '@/hooks/useToast';
import AvatarIcon from '../Global/Icons/AvatarIcon';
import * as userService from '@/services/user.service';
import clsx from 'clsx';

const MainNav = ({ hideCollapsedButton = false, closeMobileNav }) => {
  //store
  const sidebarOpen = useLayoutStore((state) => state.sidebarOpen);
  const setSidebarOpen = useLayoutStore((state) => state.setSidebarOpen);
  const setUser = useUserStore((state) => state.setUser);
  const userData = useUserStore((state) => state.user);
  const {
    owner,
    userMode,
    selectedTeam,
    setSelectedTeam,
    setUserMode,
    setTeamName,
    teamName,
    setTeams,
    setOwner,
    teams,
  } = useTeamManagementStore((state) => state);

  let location = useLocation();

  //state
  const [isCollapsed, setIsCollapsed] = useState(sidebarOpen || true);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [teamDropdownOpened, setTeamDropdownOpened] = useState(false);
  const [openModalCreateNew, setOpenModalCreateNew] = useState(false);
  const [openAIVoiceModal, setOpenAIVoiceModal] = useState(false);
  const [openAiAvatarModal, setOpenAiAvatarModal] = useState(false);
  const { addWarningToast } = useToast();
  useEffect(() => {
    if (window.innerWidth < 768) {
      setSidebarOpen(true);
    }
  }, [window.innerWidth]);

  const navigate = useNavigate();
  const initialNavLinks = [
    {
      label: 'Create new',
      icon: <AddIcon />,
      iconPlacement: 'pre',
      href: '',
      active: openModalCreateNew,
      onClick: () => {
        // navigate('/');
        setOpenModalCreateNew(true);
      },
    },
    // {
    //   label: 'New AI Chatbot',
    //   icon: <AddIcon />,
    //   iconPlacement: 'pre',
    //   active: true,
    //   href: 'chatbot/create',
    //   onClick: () => {
    //     navigate('/chatbot/create');
    //     // if (checkEnv()) {
    //     //   amplitude.track('Create a Chatbot Clicked');
    //     // }
    //   }
    // },
    {
      label: 'AI Chatbots',
      icon: <AiChatbotIcon />,
      iconPlacement: 'pre',
      active: location.pathname === '/' ||  location.pathname.startsWith('/chatbot'),
      href: '/',
      onClick: () => {
        navigate('/');
        closeMobileNav && closeMobileNav();
        // if (tierType.type.includes('free')) {
        //   e.preventDefault();
        //   notify(
        //     'cta',
        //     'Upgrade your plan to create a custom AI avatar: ',
        //     '/membership',
        //     'Upgrade'
        //   );
        // } else if (chatbots?.length === 0) {
        //   e.preventDefault();
        //   notify(
        //     'cta',
        //     "No chatbots found. Create one with 'Create Chatbot' button:",
        //     '/chatbot/new',
        //     'Create Chatbot'
        //   );
        // }
      },
      submenu: [
        {
          label: 'Human Handover',
          icon: <LiveAgentIcon />,
          iconPlacement: 'pre',
          href: '/human-handover',
          active: location.pathname.startsWith('/human-handover'),
          onClick: (e) => {
            if(!featureCheck('live_agent')) {
              addWarningToast({ message: 'Live Agent is available on higher tiers. Please upgrade to access it' });
              return;
            }
            e.preventDefault();
            navigate('/human-handover');
            closeMobileNav && closeMobileNav();
            // if (chatbots?.length === 0) {
            //   e.preventDefault();
            //   notify(
            //     'information',
            //     "No chatbots found. Create one with 'Create Chatbot' button."
            //   );
            // } else if (tierType.live_agent) {
            //   navigate('/agent');
            // } else {
            //   notify(
            //     'information',
            //     'Live Agent Takeover is current available on our Premium and Ultra Tiers. Please upgrade to continue.'
            //   );
            // }
          },
        },
      ],
    },
    {
      label: 'Digital Personas',
      icon: <AiAvatarIcon />,
      iconPlacement: 'pre',
      href: '',
      active: openAiAvatarModal,
      onClick: () => {
        setOpenAiAvatarModal(true);
        closeMobileNav && closeMobileNav();
      },
    },
    {
      label: 'AI voice',
      icon: <AIVoiceIcon />,
      iconPlacement: 'pre',
      href: '',
      active: openAIVoiceModal,
      //  location.pathname.startsWith('/voice') || location.pathname.startsWith('/phone-numbers'),
      onClick: () => {
        // navigate('/voice');
        setOpenAIVoiceModal(true);
        closeMobileNav && closeMobileNav();
      },
      // submenu: [
      //   {
      //     label: 'Phone numbers',
      //     icon: <PhoneIcon />,
      //     iconPlacement: 'pre',
      //     href: '/phone-numbers',
      //     active: location.pathname.startsWith('/phone-numbers'),
      //     onClick: () => {
      //       navigate('/phone-numbers');
      //       closeMobileNav && closeMobileNav();
      //     },
      //   },
      // ],
    },
    // {
    //   label: 'Insights',
    //   icon: <GraphIcon />,
    //   iconPlacement: 'pre',
    //   href: '/insights',
    //   active: location.pathname.startsWith('/insights'),
    //   onClick: (e) => {
    //     e.preventDefault();
    //     closeMobileNav && closeMobileNav();
    //     // if (chatbots?.length === 0) {
    //     //   e.preventDefault();
    //     //   notify(
    //     //     'information',
    //     //     "No chatbots found. Create one with 'Create Chatbot' button."
    //     //   );
    //     // } else if (tierType.live_agent) {
    //     //   navigate('/agent');
    //     // } else {
    //     //   notify(
    //     //     'information',
    //     //     'Live Agent Takeover is current available on our Premium and Ultra Tiers. Please upgrade to continue.'
    //     //   );
    //     // }
    //   },
    // },
    // {
    //   label: 'Activity Log',
    //   icon: <ActivityIcon />,
    //   iconPlacement: 'pre',
    //   href: '/activity',
    //   active: location.pathname.startsWith('/activity'),
    //   onClick: (e) => {
    //     e.preventDefault();
    //     closeMobileNav && closeMobileNav();
    //     // if (chatbots?.length === 0) {
    //     //   e.preventDefault();
    //     //   notify(
    //     //     'information',
    //     //     "No chatbots found. Create one with 'Create Chatbot' button."
    //     //   );
    //     // } else if (tierType.live_agent) {
    //     //   navigate('/agent');
    //     // } else {
    //     //   notify(
    //     //     'information',
    //     //     'Live Agent Takeover is current available on our Premium and Ultra Tiers. Please upgrade to continue.'
    //     //   );
    //     // }
    //   },
    // },
  ];

  const [navLinks, setNavLinks] = useState(initialNavLinks);

  const isAboveSm = useIsAboveBreakpoint('sm');

  const handleToggleNav = () => {
    setIsCollapsed(!isCollapsed);
    setSidebarOpen(!sidebarOpen);
  };

  const getTeams = async () => {
    const response = await teamManagementService.getTeams();
    setOwner(
      response.data.results.find((team) => team.owner_id === userData.id)
    );
    setTeams(response.data.results);
  };

  const handleTeamManagementClick = async () => {
    setTeamDropdownOpened(false);
    try {
      if (teams.length === 0) {
        const response = await teamManagementService.createTeam(
          `${userData.first_name}'s team`
        );
        if (response.status === 200) {
          navigate(`/team-management/${response.data.id}`);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const toggleSubmenu = (label) => {
    setNavLinks((prev) =>
      prev.map((item) =>
        item.label === label
          ? { ...item, submenuOpen: !item.submenuOpen }
          : item
      )
    );
  };

  const handleTeamClick = async (e, team) => {
    e.stopPropagation();
    setUserMode(false);
    setSelectedTeam(team);
    setTeamName(team.name);
    navigate(`/team-management/${team.id}`);
  };

  const handleLeaveTeam = async (id) => {
    try {
      const response = await teamManagementService.leaveTeam(id);
      if (response.status === 200) {
        setSelectedTeam(null);
        setUserMode(true);
        setTeamDropdownOpened(false);
        navigate('/');
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  //call getTeams to get all teams
  useEffect(() => {
    getTeams();
  }, [teamName]);

  useEffect(() => {
    setIsCollapsed(!sidebarOpen);
  }, [sidebarOpen]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchUserProfile = async () => {
      try {
        const response = await userService.getUserProfile({ signal });
        if (response.status === 200) {
          setUser(response.data);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.log('error', error);
        }
      }
    };

    fetchUserProfile();

    return () => {
      controller.abort();
    };
  }, [selectedTeam]);

  return (
    <div
      className={`main_nav_wrapper  bg-white md:max-w-[220px] px-size1 py-size3 xl:p-size3 ${
        isCollapsed ? 'collapsed delay-150' : ''
      }`}
    >
      <div
        className={`flex flex-col w-full ${
          (dropdownOpened || teamDropdownOpened) && 'dropdown_opened'
        }`}
      >
        <div
          className={`flex items-center ${
            isCollapsed ? 'justify-between' : ' justify-between'
          } gap-1 text-base w-full`}
        >
          <Transition show={!isCollapsed}>
            <button
              className="transition-all data-[closed]:opacity-0 data-[enter]:delay-300"
              onClick={() => navigate('/')}
            >
              <DFullLogo />
            </button>
          </Transition>
          {!hideCollapsedButton && (
            <div
              className={clsx(
                'transition-all data-[closed]:opacity-0 data-[enter]:delay-300 flex justify-center items-center',
                {
                  'w-full': isCollapsed,
                }
              )}
            >
              <button onClick={handleToggleNav}>
                {isCollapsed ? <OpenNavIcon /> : <CloseNavIcon />}
              </button>
            </div>
          )}
          {!isAboveSm && (
            <div className="flex gap-size2 items-center justify-end">
              <DNotificationHeader />
              <DButtonIcon size="lg">
                <BookIcon className="w-6 h-6" />
              </DButtonIcon>
              <HeaderAccountMenu />
              <DButtonIcon
                size="lg"
                onClick={closeMobileNav}
                className="border border-grey-5 rounded-size0"
              >
                <CloseIcon className="w-6 h-6" />
              </DButtonIcon>
            </div>
          )}
        </div>
        <div className="border border-grey-5 w-full my-size4"></div>
        <div className="main_nav-nav_links w-full flex flex-col gap-size2">
          {navLinks.map((navLink, index) => (
            <div key={navLink.label} className="relative">
              {/* Main Nav Link */}
              <DNavLink
                label={isCollapsed ? '' : navLink.label}
                icon={navLink.icon}
                iconPlacement={navLink.iconPlacement}
                active={navLink.active}
                collapsed={isCollapsed}
                onClick={() => {
                  if (navLink?.submenu && navLink.submenu.length > 0) {
                    toggleSubmenu(navLink.label);
                    navLink.onClick();
                  } else {
                    navLink.onClick();
                  }
                }}
                className={`flex items-center w-full ${
                  navLink?.submenu && navLink.submenu.length > 0
                    ? 'cursor-pointer'
                    : ''
                } ${isCollapsed ? '' : ''}`}
              >
                {!isCollapsed && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSubmenu(navLink.label);
                    }}
                  >
                    {navLink?.submenu && navLink.submenu.length > 0 && (
                      <ChevronDownIcon
                        className={`w-3 h-3 text-grey-20 ${
                          navLink.submenuOpen ? 'rotate-180' : ''
                        }`}
                      />
                    )}
                  </button>
                )}
              </DNavLink>

              {/* Submenu */}
              <DTransition
                show={
                  navLink?.submenu &&
                  navLink.submenu.length > 0 &&
                  navLink.submenuOpen
                }
                className="mt-size2"
                type="collapse"
              >
                {navLink?.submenu?.map((subNav) => (
                  <DNavLink
                    key={subNav.label}
                    label={isCollapsed ? '' : subNav.label}
                    icon={subNav.icon}
                    iconPlacement={subNav.iconPlacement}
                    active={subNav.active}
                    onClick={subNav.onClick}
                  />
                ))}
              </DTransition>

              {/* Divider */}
              {index === 4 && (
                <div className="h-px w-full bg-gray-300 mt-2"></div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col w-full gap-size3">
        <Transition show={isCollapsed}>
          <div className="transition-all duration-300 data-[closed]:opacity-0 data-[enter]:delay-300 flex justify-center items-center">
            <DCircularChart
              value={userData?.credits_key?.percentage}
              size="sm"
            />
          </div>
        </Transition>
        <Transition show={!isCollapsed}>
          <div className="transition-all duration-300 data-[closed]:opacity-0 data-[enter]:delay-300 flex flex-col gap-size1">
            <div className="flex w-full items-center justify-between gap-size1">
              <p className="text-sm tracking-tight text-grey-50">
                <span className="text-black">
                  {userData?.credits_key?.credits_available}
                </span>{' '}
                credits left
              </p>
            </div>
            <div className="w-full bg-green-10 h-0.5">
              <div
                className="bg-green-400 h-0.5"
                style={{
                  width: `${Math.ceil(userData?.credits_key?.percentage)}%`,
                }}
              ></div>
            </div>
          </div>
        </Transition>
        <Transition show={!isCollapsed && userData?.tier_on_trial}>
          <div className="transition-all duration-300 data-[closed]:opacity-0 data-[enter]:delay-300 flex flex-col gap-size1">
            <div className="flex w-full items-center justify-between gap-size1">
              <p className="text-sm tracking-tight text-grey-50">
                <span className="text-black">
                {Math.round(DateTime.fromISO(userData?.tier_key?.renew_date).diff(DateTime.now(), 'days').days)}
                </span>{' '}
                days left
              </p>
            </div>
            <div className="w-full bg-green-10 h-0.5">
              <div
                className="bg-green-400 h-0.5"
                style={{
                  width: `${Math.min(DateTime.fromISO(userData?.tier_key?.renew_date).diffNow('days').days / 14, 100)}%`,
                }}
              ></div>
            </div>
          </div>
        </Transition>

        <div
          className={`flex flex-col w-full gap-size0 relative ${
            teamDropdownOpened && 'dropdown_opened'
          }`}
        >
          <Menu>
            {({ open }) => {
              if (dropdownOpened !== open) {
                setDropdownOpened(open);
              }
              return (
                <>
                  <MenuButton className="h-12">
                    <div
                      className={`flex items-center w-full ${
                        isCollapsed ? 'justify-center' : 'justify-between '
                      }`}
                    >
                      <div className="flex items-center gap-size1">
                        <ClockIcon />
                        {!isCollapsed && (
                          <span className="text-xs font-medium tracking-tight">
                            Account limits
                          </span>
                        )}
                      </div>
                      {!isCollapsed && (
                        <ChevronDownIcon
                          className={`w-size2 h-size2 text-grey-50 ${
                            open ? 'rotate-180' : ''
                          }`}
                        />
                      )}
                    </div>
                  </MenuButton>
                  <MenuItems
                    anchor={isCollapsed ? 'right end' : 'top'}
                    className={`border border-grey-5 bg-white rounded-size0 w-[210px] flex flex-col gap-size3 py-size1 px-size2`}
                  >
                    <MenuItem>
                      <div className="flex flex-col gap-size1">
                        <div className="flex w-full items-center justify-between gap-size1">
                          <p className="text-sm tracking-tight text-grey-50">
                            <span className="text-black">
                              {userData?.used_ai_chatbots}
                            </span>
                            /{userData?.total_ai_chatbots} AI chatbots
                          </p>
                        </div>
                        <div class="w-full bg-negative-10 h-0.5">
                          <div
                            class="bg-negative-400 h-0.5"
                            style={{ width: '65%' }}
                          ></div>
                        </div>
                      </div>
                    </MenuItem>
                    {/* <MenuItem>
                      <div className="flex flex-col gap-size1">
                        <div className="flex w-full items-center justify-between gap-size1">
                          <p className="text-sm tracking-tight text-grey-50">
                            <span className="text-black">1</span>/3 digital personas
                          </p>
                        </div>
                        <div class="w-full bg-orange-10 h-0.5">
                          <div
                            class="bg-orange-400 h-0.5"
                            style={{ width: '65%' }}
                          ></div>
                        </div>
                      </div>
                    </MenuItem> */}
                  </MenuItems>
                </>
              );
            }}
          </Menu>
        </div>
        <div className="border border-grey-5 w-full"></div>
        <div
          className={`main_nav-team_management relative w-full justify-center items-center flex ${
            dropdownOpened && 'dropdown_opened'
          }`}
        >
          <Menu>
            {({ open }) => {
              if (teamDropdownOpened !== open) {
                setTeamDropdownOpened(open);
              }
              return (
                <>
                  <MenuButton
                    className={`flex items-center w-full rounded-size3 hover:bg-grey-2 ${
                      !isCollapsed ? 'justify-between' : 'justify-between'
                    }`}
                  >
                    <div className={`flex items-center gap-size1 text-base font-regular ${isCollapsed ? 'm-auto' : ''}`}>
                      <img
                        src={
                          selectedTeam?.team_image ??
                          'https://dante-ai-files.s3.eu-west-1.amazonaws.com/profile-images/team/team-01-small.png'
                        }
                        alt="Team Management Avatar"
                        className="size-10"
                      />
                      {!isCollapsed && (
                        <span>
                          {selectedTeam
                            ? `${selectedTeam?.name}`
                            : `Choose a team`}
                        </span>
                      )}
                    </div>
                    {!isCollapsed && <ExpandIcon className="text-grey-50" />}
                  </MenuButton>
                  <MenuItems
                    anchor={isCollapsed ? 'right end' : 'top'}
                    className="border border-grey-5 bg-white rounded-size2 w-[210px] shadow-md p-size1 flex flex-col gap-size1"
                  >
                    {teams.map((team, index) => (
                      <MenuItem
                        key={team.id}
                        onClick={(e) => handleTeamClick(e, team)}
                      >
                        <a
                          className="flex justify-between gap-size0 items-center hover:bg-grey-2"
                          href=""
                        >
                          <div className="flex items-center gap-size1 text-sm font-regular justify-between">
                            <img
                              src={
                                team?.team_image ??
                                'https://dante-ai-files.s3.eu-west-1.amazonaws.com/profile-images/team/team-02-small.png'
                              }
                              alt="Team Management Avatar"
                              className="w-8 h-8"
                            />
                            <span
                              className={`truncate ${
                                owner?.owner_id === team?.owner_id
                                  ? 'max-w-[120px]'
                                  : 'max-w-[85px]'
                              }`}
                            >
                              {team.name}
                            </span>
                          </div>
                          <span className="flex items-center justify-center h-4 text-black bg-grey-5 py-o.5 px-size0 rounded-size0 text-[8px] font-bold">
                            {owner?.owner_id === team.owner_id
                              ? 'Owner'
                              : 'Guest'}
                          </span>
                          {(!userMode || owner?.owner_id !== team.owner_id) && (
                            <Menu>
                              {({ open, close }) => (
                                <>
                                  <MenuButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (open) close();
                                    }}
                                    className="h-[50px]"
                                  >
                                    <OptionsHorizontalIcon />
                                  </MenuButton>
                                  <MenuItems
                                    anchor="bottom end"
                                    className="border border-grey-5 bg-white w-[190px] rounded-size0 shadow-md p-size1 flex flex-col gap-size1"
                                  >
                                    {!userMode && (
                                      <MenuItem
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedTeam(null);
                                          setUserMode(true);
                                          setTeamDropdownOpened(false);
                                          navigate('/');
                                        }}
                                      >
                                        <div className="flex items-center gap-size1 cursor-pointer">
                                          <AvatarIcon className="size-5" />
                                          <span className="text-sm font-regular">
                                            Switch to user mode
                                          </span>
                                        </div>
                                      </MenuItem>
                                    )}
                                    {owner?.owner_id !== team?.owner_id && (
                                      <MenuItem
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleLeaveTeam(team.id);
                                        }}
                                      >
                                        <div className="flex items-center gap-size1">
                                          <LeaveTeamIcon className="text-negative-200" />
                                          <span className="text-sm font-regular cursor-pointer text-negative-200">
                                            Leave Team
                                          </span>
                                        </div>
                                      </MenuItem>
                                    )}
                                  </MenuItems>
                                </>
                              )}
                            </Menu>
                          )}
                        </a>
                      </MenuItem>
                    ))}
                    <div className="h-px w-full bg-grey-5"></div>
                    <span
                      className="text-sm font-regular cursor-pointer"
                      onClick={handleTeamManagementClick}
                    >
                      Team Management
                    </span>
                  </MenuItems>
                </>
              );
            }}
          </Menu>
        </div>
      </div>
      <DModalCreateNew
        open={openModalCreateNew}
        onClose={() => {
          setOpenModalCreateNew(false);
          closeMobileNav && closeMobileNav();
        }}
        handleOpenModalBetaVoice={() => {
          setOpenAIVoiceModal(true);
        }}
        handleOpenModalBetaAvatar={() => {
          setOpenAiAvatarModal(true);
        }}
      />
      <DModalBetaVoice
        open={openAIVoiceModal}
        onClose={() => {
          setOpenAIVoiceModal(false);
        }}
      />
      <DModalBetaAvatar
        open={openAiAvatarModal}
        onClose={() => {
          setOpenAiAvatarModal(false);
        }}
      />
    </div>
  );
};

export default MainNav;
