import React from 'react';
import { useNavigate } from 'react-router-dom';
import DNotificationHeader from '@/components/DNotificationHeader';
import DButton from '@/components/Global/DButton';
import DButtonIcon from '@/components/Global/DButtonIcon';
import DProgressBar from '@/components/Global/DProgressBar';
import BookIcon from '@/components/Global/Icons/BookIcon';
import ZapIcon from '@/components/Global/Icons/ZapIcon';
import HeaderAccountMenu from '@/components/HeaderAccountMenu';
import MainNav from '@/components/MainNav';
import HeaderLearningHub from '../../components/HeaderLearningHub';
import DToastContainer from '@/components/DToast/DToastContainer';

/**
 * This layout component displays the children content
 * when the device screen size is equal to or larger than the `sm` breakpoint.
 */
const LayoutMediumScreen = ({ children, title, progressBar }) => {
  const navigate = useNavigate();
  return (
    <main className="layout-medium-screen d-h-screen flex flex-row gap-size2 xl:gap-size5 w-full p-size3">
      <MainNav />

      <div className="w-full flex flex-col h-full gap-size2">
        <header className="flex justify-between">
          <div className="flex flex-col gap-size1 w-full md:max-w-[50%]">
            {title && <h1 className="text-xl">{title}</h1>}
            {progressBar && progressBar.length > 0 && (
              <DProgressBar steps={progressBar} />
            )}
          </div>

          <DToastContainer hideInMobile />

          <div className="flex gap-size2 items-center justify-end">
            <DButton
              variant="green"
              className="!gap-size0"
              onClick={() => navigate('/plans')}
            >
              <ZapIcon />
              <span className="mr-size0">Add plan</span>
            </DButton>
            <DNotificationHeader />
            <HeaderLearningHub />
            <HeaderAccountMenu />
          </div>
        </header>
        <div className="h-[1px] flex flex-col grow">{children}</div>
      </div>
    </main>
  );
};

export default LayoutMediumScreen;
