import { lazy, Suspense } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import Entrypoint from './Entrypoint';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import DLoading from '@/components/DLoading';

const LoginPage = lazy(() => import('@/pages/Auth/Login'));
const ForgotPasswordPage = lazy(() => import('@/pages/Auth/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('@/pages/Auth/ResetPassword'));
const Home = lazy(() => import('@/pages/Home'));

const CreateChatbot = lazy(() => import('@/pages/CreateChatbot'));
const ChatbotDetails = lazy(() => import('@/pages/ChatbotDetails'));
const ChatbotDetailsOutlet = lazy(
  () => import('@/pages/ChatbotDetails/ChatbotDetailsOutlet')
);
const ChatbotTabs = lazy(() => import('@/pages/ChatbotDetails/ChatbotTabs'));
const ChatbotInsights = lazy(
  () => import('@/pages/ChatbotDetails/ChatbotInsights')
);
const ChatbotIntegrations = lazy(
  () => import('@/pages/ChatbotDetails/ChatbotIntegrations')
);
const ChatbotSafety = lazy(
  () => import('@/pages/ChatbotDetails/ChatbotSafety')
);
const ChatbotKnowledge = lazy(
  () => import('@/pages/ChatbotDetails/ChatbotKnowledge')
);
const ChatbotSetup = lazy(() => import('@/pages/ChatbotDetails/ChatbotSetup'));
const ChatbotStyling = lazy(
  () => import('@/pages/ChatbotDetails/ChatbotStyling')
);
const ChatbotPowerups = lazy(
  () => import('@/pages/ChatbotDetails/ChatbotPowerups')
);
const TeamManagement = lazy(() => import('@/pages/TeamManagement'));
const Profile = lazy(() => import('@/pages/Profile'));
const Membership = lazy(() => import('@/pages/Membership'));
const ErrorPage = lazy(() => import('@/pages/Error'));
const WhatsappIntegration = lazy(
  () => import('@/pages/ChatbotDetails/Integrations/WhatsappIntegration')
);
const IntercomIntegration = lazy(
  () => import('@/pages/ChatbotDetails/Integrations/IntercomIntegration')
);
const ChatRecordDeatil = lazy(
  () => import('@/pages/ChatbotDetails/ChatRecordDeatil/ChatRecordDeatil')
);

const Voice = lazy(() => import('@/pages/Voice'));
const CreateVoice = lazy(() => import('@/pages/CreateVoice'));
const PhoneNumbers = lazy(() => import('@/pages/Voice/PhoneNumbers'));
const EditVoice = lazy(() => import('@/pages/EditVoice'));
const SignUp = lazy(() => import('@/pages/SignUp'));

const HumanHandover = lazy(() => import('@/pages/HumanHandover'));

const Subscribe = lazy(() => import('@/pages/Subscribe'));

const Plans = lazy(() => import('@/pages/Plans'));
const MembershipPage = lazy(() => import('@/pages/Membership'));
const Branding = lazy(() => import('@/pages/Branding'));
const Upgrade = lazy(() => import('@/pages/Upgrade'));
const Success = lazy(() => import('@/pages/Stripe/Success'));
const Cancel = lazy(() => import('@/pages/Stripe/Cancel'));
const Onboarding = lazy(() => import('@/pages/Onboarding'));

/** @type {import('react-router-dom').RouteObject[]} */
const publicRoutes = [
  {
    path: 'log-in',
    element: (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: 'sign-up',
    element: (
      <PublicRoute>
        <SignUp />
      </PublicRoute>
    ),
  },
  {
    path: 'forgot-password',
    element: (
      <PublicRoute>
        <ForgotPasswordPage />
      </PublicRoute>
    ),
  },
  {
    path: 'request-token',
    element: <PublicRoute>RequestToken</PublicRoute>,
  },
  {
    path: 'change-password',
    element: <PublicRoute>ChangePassword</PublicRoute>,
  },
  {
    path: 'verify-email',
    element: <PublicRoute>VerifyEmail</PublicRoute>,
  },
  {
    path: 'reset-password',
    element: (
      <PublicRoute>
        <ResetPasswordPage />
      </PublicRoute>
    ),
  },
];

/** @type {import('react-router-dom').RouteObject[]} */
const privateRoutes = [
  {
    path: '',
    element: <Home />,
  },
  {
    path: 'chatbot/create',
    element: <CreateChatbot />,
  },
  {
    path: 'chatbot/:id',
    element: <ChatbotDetailsOutlet />,
    children: [
      {
        path: '',
        element: <ChatbotDetails />,
      },
      {
        path: 'tabs',
        element: <ChatbotTabs />,
      },
      {
        path: 'insights',
        element: <ChatbotInsights />,
      },
      {
        path: 'insights/record/:recordId',
        element: <ChatRecordDeatil />,
      },
      {
        path: 'integrations',
        element: <ChatbotIntegrations />,
      },
      {
        path: 'integrations/whatsapp',
        element: <WhatsappIntegration />,
      },
      {
        path: 'integrations/intercom',
        element: <IntercomIntegration />,
      },
      {
        path: 'safety',
        element: <ChatbotSafety />,
      },
      {
        path: 'knowledge',
        element: <ChatbotKnowledge />,
      },
      {
        path: 'setup',
        element: <ChatbotSetup />,
      },
      {
        path: 'styling',
        element: <ChatbotStyling />,
      },
      {
        path: 'powerups',
        element: <ChatbotPowerups />,
      },
    ],
  },
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'membership',
    element: <Membership />,
  },
  {
    path: 'team-management',
    element: <TeamManagement />,
    children: [
      {
        path: ':id',
        element: <TeamManagement />,
      },
    ],
  },
  {
    path: 'branding',
    element: <Branding />,
  },
  {
    path: 'voice',
    element: <Voice />,
  },
  {
    path: 'voice/create',
    element: <CreateVoice />,
  },
  {
    path: 'phone-numbers',
    element: <PhoneNumbers />,
  },
  {
    path: 'voice/:id/edit',
    element: <EditVoice />,
  },
  {
    path: 'human-handover',
    element: <HumanHandover />,
  },
  {
    path: 'subscribe',
    element: <Subscribe />,
  },
  {
    path: 'plans',
    element: <Plans />,
  },
  {
    path: 'membership',
    element: <MembershipPage />,
  },
];

const routes = [
  ...publicRoutes,

  {
    path: '/',
    element: (
      <PrivateRoute>
        <Entrypoint />
      </PrivateRoute>
    ),
    errorElement: (
      <PrivateRoute>
        <ErrorPage />
      </PrivateRoute>
    ),
    children: [...privateRoutes],
  },
  {
    path: '/onboarding',
    element: (
      <PrivateRoute>
        <Outlet /> 
      </PrivateRoute>
    ),
    children: [
      { path: 'upgrade', element: <Upgrade /> },
      { path: 'create', element: <Onboarding />}
    ],
  },
  {
    path: '/stripe',
    element: (
      <PrivateRoute>
        <Outlet /> 
      </PrivateRoute>
    ),
    children: [
      { path: 'success', element: <Success /> },
      { path: 'cancel', element: <Cancel /> },
    ],
  },
];

const router = createBrowserRouter(routes, { basename: '/' });

const Routers = () => {
  return (
    <Suspense fallback={<DLoading show={true} />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
export default Routers;
