//this file contains the logic to check if a feature is available for a given tier

import React from 'react';
import useTeamManagementStore from "@/stores/teamManagement/teamManagementStore";
import { useUserStore } from "@/stores/user/userStore";

const features = {
    'api_key': ['business', 'professional', 'ultra', 'pro', 'enterprise'],
    'remove_powered_by_dante': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'show_sources': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'live_agent': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'integrations': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'chatbot_security': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'max_credits': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'response_limit': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'conversation_limit': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'chatbot_password': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
    'custom_roles': ['business', 'professional','premium', 'ultra', 'advanced', 'pro', 'enterprise'],
}

export default function featureCheck(feature) {
    //get tier_type from user store
    const tier_type = useUserStore.getState().user.tier_type;

    //check if feature is available for the tier
    if (features[feature].includes(tier_type)) {
        return true;
    }
    return false;
}


export const checkTeamManagementPermission = (feature) => {
    const user = useUserStore.getState().user;
    const hasTeamSelected = useTeamManagementStore.getState().selectedTeam?.id;
    const owner = useTeamManagementStore.getState().owner;
    const userPermissions = useUserStore.getState().permissions;
    if(hasTeamSelected && owner.owner_id !== user.id) {
      return userPermissions?.find((permission) => permission.name === feature)?.value;
    }
    return true;
}

export const withPermissionCheck = (WrappedComponent, requiredPermission) => {
  return function PermissionWrapper(props) {
    const hasPermission = checkTeamManagementPermission(requiredPermission);
    
    if (!hasPermission) {
      return null;
    }
    
    return React.createElement(WrappedComponent, props);
  };
};