import clsx from 'clsx';

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

import DNotificationBlockTasks from './tasks';
import DNotificationBlockUpdates from './updates';

const DNotificationBlock = () => {
  const tabClass = clsx(
    'w-full gap-size0 bg-grey-2 rounded-size0 flex justify-center items-center text-grey-20 h-size7',
    'data-[selected]:bg-grey-5 data-[selected]:text-black text-sm'
  );

  return (
    <TabGroup>
      <TabList className="flex gap-size1">
        <Tab className={tabClass}>Updates</Tab>
        {/* <Tab className={tabClass}>Tasks</Tab> */}
      </TabList>
      <TabPanels>
        <TabPanel>
          <DNotificationBlockUpdates />
        </TabPanel>
        {/* <TabPanel>
          <DNotificationBlockTasks />
        </TabPanel> */}
      </TabPanels>
    </TabGroup>
  );
};

export default DNotificationBlock;
