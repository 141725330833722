import * as authService from '@/services/auth.service';
import { useUserStore } from '@/stores/user/userStore';

const refreshAccessToken = async () => {
  const { updateAccessToken, removeAuthDetail } = useUserStore.getState();

  try {
    const { data } = await authService.refreshToken();
    if (data?.access_token) {
      updateAccessToken(data.access_token);
    } else {
      throw new Error('Invalid token data');
    }
  } catch (error) {
    console.error('Failed to refresh access token:', error);
    removeAuthDetail();
  }
};

export default refreshAccessToken;
