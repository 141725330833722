import { Transition } from '@headlessui/react';
import clsx from 'clsx';

const DNavLink = ({
  disabled,
  variant,
  iconPlacement,
  label,
  icon,
  props,
  active,
  collapsed,
  onClick,
  className,
  page,
  children,
}) => {
  let buttonStyle = '';

  if ((variant === 'contained' || active) && page !== 'ChatbotSidebar') {
    buttonStyle = 'bg-purple-200 text-[#fff]';
  } else if (page === 'ChatbotSidebar' && active) {
    buttonStyle = 'bg-white text-black';
  } else if (variant === 'light') {
    buttonStyle = 'bg-white text-black';
  } else if (disabled) {
    buttonStyle = 'bg-transparent text-grey-20';
  } else {
    buttonStyle = 'bg-transparent';
  }

  return (
    <button
      className={`${buttonStyle} p-size2 flex items-center rounded-size0 h-11 w-full ${className} ${
        iconPlacement === 'post' || children ? 'justify-between' : 'gap-2'
      } hover:bg-grey-2 hover:text-black`}
      disabled={disabled}
      onClick={onClick}
      key={label}
      {...props}
    >
      <div className="flex gap-2">
        <Transition show={collapsed || iconPlacement === 'pre'}>
          <span
            className={clsx('transition-all duration-300 pl-0', {
              'w-full flex items-center pl-[5px]': collapsed,
            })}
          >
            {icon}
          </span>
        </Transition>
        <Transition show={!collapsed && label}>
          <span className="transition-all duration-300 data-[closed]:opacity-0 data-[enter]:delay-300 text-base nav-label w-max whitespace-nowrap">
            {label}
          </span>
        </Transition>
        <Transition show={!collapsed && iconPlacement === 'post'}>
          <span>{icon}</span>
        </Transition>
      </div>
      {children}
    </button>
  );
};

export default DNavLink;
