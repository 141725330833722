import { create } from 'zustand';

const initialState = {
  notifications: {
    updates: [],
  },
};

const useNotificationsStore = create((set) => ({
  ...initialState,
  addNotifications: (newUpdates) => {
    set((state) => {
      const deduplicatedUpdates = newUpdates.filter(
        (newNotification) =>
          !state.notifications.updates.some((existing) => existing.id === newNotification.id)
      );

      return {
        notifications: {
          updates: [...state.notifications.updates, ...deduplicatedUpdates],
        },
      };
    });
  },
  markAsRead: (id) => {
    set((state) => ({
      notifications: {
        updates: state.notifications.updates.map((notification) =>
          notification.id === id ? { ...notification, seen: true } : notification
        ),
      },
    }));
  },
  markAllAsRead: () => {
    set((state) => ({
      notifications: {
        updates: state.notifications.updates.map((notification) => ({ ...notification, seen: true })),
      },
    }));
  },
  clearNotifications: () => {
    set({
      notifications: {
        updates: [],
      },
    });
  },
  clearNotification: (id) => {
    set((state) => ({
      notifications: {
        updates: state.notifications.updates.filter((notification) => notification.id !== id),
      },
    }));
  },
  reset: () => set({ ...initialState })
}));

export default useNotificationsStore;
