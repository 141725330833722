import React from 'react';

import DSpinner from '../DSpinner';
import CheckIcon from '../Icons/CheckIcon';
import FlagIcon from '../Icons/FlagIcon';

const DProgressBar = ({ steps }) => {
  return (
    <div className="flex items-center w-full">
      {steps.map((step, index) => (
        <React.Fragment key={step.id}>
          <div className="flex items-center w-full md:h-10">
            {index <= steps.length - 1 && (
              <div className="w-full h-1 bg-gray-300">
                {step.completed && <div className="w-full h-full bg-green-500"></div>}
                {step.active && <div className="w-1/2 h-full bg-green-500"></div>}
              </div>
            )}
            {step.completed && index !== steps.length - 1 && (
              <div className="w-7 h-7 bg-darkGreen-10 rounded-full flex items-center justify-center flex-shrink-0 mx-size0">
                <CheckIcon className="w-4 h-4 text-darkGreen-300" />
              </div>
            )}
            {step.active && index !== steps.length - 1 && (
              <DSpinner width={42} height={42} className="text-darkGreen-300 flex-shrink-0" />
            )}
            {(step.pending || step.active) && index === steps.length - 1 && (
              <FlagIcon className="w-5 h-5 text-gray-400 ml-size0" />
            )}

            {step.completed && index === steps.length - 1 && (
              <FlagIcon className="w-5 h-5 text-green-500" />
            )}

            {step.pending && index !== steps.length - 1 && (
              <DSpinner width={42} height={42} className="text-gray-400 flex-shrink-0" />
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default DProgressBar;
