import React, { useEffect, useState } from 'react';
import Routers from '../../router/main';

const hardcodedPassword = 'Dante@111';
const passwordKey = 'lastAuthTime';
const expires = 2 * 60 * 60 * 1000; // 2 hours

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [checkedAuth, setCheckedAuth] = useState(false);
  const [inputPassword, setInputPassword] = useState('');

  const checkAuthentication = () => {
    const lastAuthTime = localStorage.getItem(passwordKey);
    if (!lastAuthTime) return false;
    const currentTime = new Date().getTime();
    return currentTime - parseInt(lastAuthTime, 10) < expires;
  };

  useEffect(() => {
    const alreadyAuthenticated = checkAuthentication();
    setIsAuthenticated(alreadyAuthenticated);
    setCheckedAuth(true);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputPassword === hardcodedPassword) {
      localStorage.setItem(passwordKey, new Date().getTime().toString());
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password. Redirecting...');
      window.location.href = 'https://www.google.com/';
    }
  };

  // If we haven't finished checking authentication status, don't render anything
  if (!checkedAuth) {
    return null; // or a loading spinner if you'd like
  }

  // If not authenticated, display the password form
  if (!isAuthenticated) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          fontFamily: 'Arial, sans-serif'
        }}
      >
        <h2>Please Enter the Password</h2>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '200px' }}>
          <input
            type="password"
            value={inputPassword}
            onChange={(e) => setInputPassword(e.target.value)}
            placeholder="Password"
            style={{ padding: '8px', fontSize: '14px' }}
          />
          <button type="submit" style={{ padding: '8px', fontSize: '14px', cursor: 'pointer' }}>
            Submit
          </button>
        </form>
      </div>
    );
  }

  // If authenticated, show the main app
  return <Routers />;
};

export default App;
