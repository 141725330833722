import clsx from 'clsx';
import { useState, useEffect } from 'react';

const DCircularChart = ({ value, variant, size = 'md' }) => {
  const [animatedValue, setAnimatedValue] = useState(0);

  useEffect(() => {
    let startValue = animatedValue;
    let startTime;

    const animate = (time) => {
      if (!startTime) startTime = time;
      const progress = (time - startTime) / 500; // 500ms animation duration
      const nextValue =
        startValue +
        ((value > 100 ? 100 : value < 0 ? 0 : value) - startValue) *
          Math.min(progress, 1);

      setAnimatedValue(nextValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  }, [value]);

  const percentage = 100 - animatedValue;

  return (
    <div
      className={clsx('relative ', {
        'size-6': size === 'sm',
        'size-8': size === 'md',
      })}
    >
      <svg
        className="size-full -rotate-90"
        viewBox="0 0 36 36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-grey-10"
          strokeWidth="2"
        ></circle>

        <circle
          cx="18"
          cy="18"
          r="16"
          fill="none"
          className="stroke-current text-green-100"
          strokeWidth="4"
          strokeDasharray="100"
          strokeDashoffset={percentage}
          strokeLinecap="round"
        ></circle>
      </svg>
    </div>
  );
};

export default DCircularChart;
