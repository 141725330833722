import { useState } from 'react';
import { useUserStore } from '@/stores/user/userStore';
import DModal from '../Global/DModal';
import DTextArea from '../Global/DInput/DTextArea';
import DButton from '../Global/DButton';
import { applyForBetaAccess } from '@/services/avatar.service';
import useToast from '@/hooks/useToast';

const DModalBetaAvatar = ({ open, onClose }) => {
  const user = useUserStore((state) => state.user);
  const [reason, setReason] = useState('');
  const { addSuccessToast } = useToast();

  const handleApply = async () => {
    try {
      const response = await applyForBetaAccess(reason);
      if (response.status === 200) {
        addSuccessToast({ message: 'Your application has been submitted successfully' });
        onClose();
      }
    } catch (error) {
      console.error('Failed to apply for beta access:', error);
    }
  };

  return (
    <DModal
      isOpen={open}
      onClose={onClose}
      title="Join the Beta for AI Avatars!"
      footer={
        <DButton fullWidth variant="dark" onClick={handleApply}>
          Request Access
        </DButton>
      }
    >
      <div className="flex flex-col gap-size3">
        <p className="text-sm font-regular text-grey-50">
        Get exclusive early access to test and refine this cutting-edge technology.
        </p>
        <div className="flex gap-size2">
          <img
            src={
              user?.profile_image ||
              'https://dante-ai-files.s3.eu-west-1.amazonaws.com/profile-images/team/team-01-small.png'
            }
            alt="user avatar"
            className="w-12 h-12 rounded-full"
          />
          <div className="flex flex-col gap-size0">
            <p className="text-sm font-medium">{user?.full_name}</p>
            <p className="text-sm font-light">{user?.email}</p>
          </div>
        </div>
        <DTextArea placeholder="Why do you want to join the beta?" value={reason} onChange={(e) => setReason(e.target.value)} />
      </div>
    </DModal>
  );
};

export default DModalBetaAvatar;
