import generateApiEndpoint from '@/helpers/generateApiEndpoint';
import http from './http';
import { DEFAULT_HEADERS, UPLOAD_FILE_HEADERS } from './constants.service';


export const getUserInfo = (data) => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'users/me', data, {
    headers: DEFAULT_HEADERS
  });
};

export const getUserProfile = () => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'profile/all', {
    headers: DEFAULT_HEADERS
  });
};

export const updateUserInfo = (data) => {
  return http.patch(`${import.meta.env.VITE_APP_BASE_API}users/me`, data, {
    headers: DEFAULT_HEADERS
  });
};

export const deleteUser = () => {
  return http.delete(import.meta.env.VITE_APP_BASE_API + 'user', {}, {
    headers: DEFAULT_HEADERS
  });
};

export const updateUserProfile = (data) => {
  return http.patch(import.meta.env.VITE_APP_BASE_API + 'profile', data, {
    headers: DEFAULT_HEADERS
  });
};

export const createUserAPIKey = () => {
  return http.post(
    import.meta.env.VITE_APP_BASE_API + 'user/api_key',
    {},
    {
      headers: DEFAULT_HEADERS
    }
  );
};

export const getUserAPIKey = () => {
  return http.get(
    import.meta.env.VITE_APP_BASE_API + 'user/api_key',
    {},
    {
      headers: DEFAULT_HEADERS
    }
  );
};

export const deleteUserAPIKey = () => {
  return http.delete(
    import.meta.env.VITE_APP_BASE_API + 'user/api_key',
    {},
    {
      headers: DEFAULT_HEADERS
    }
  );
};


export const createOpenAIKey = (key) => {
  return http.post(import.meta.env.VITE_APP_BASE_API + 'user/add_openai_key', key, {
    headers: DEFAULT_HEADERS
  });
};


export const resetPassword = (data) => {
  return http.post(`${import.meta.env.VITE_APP_BASE_API}user/reset-password`, data, {
    headers: DEFAULT_HEADERS
  });
};

export const generate2FAQRCode = () => {
  return http.post(import.meta.env.VITE_APP_BASE_API + 'user/generate-2fa-qrcode', {}, {
    headers: DEFAULT_HEADERS
  });
};

export const generate2FASetupKey = () => {
  return http.post(
    import.meta.env.VITE_APP_BASE_API + 'user/generate-2fa-setup-key',
    {},
    {
      headers: DEFAULT_HEADERS
    }
  );
};

export const check2FAOTP = (otp) => {
  return http.post(
    import.meta.env.VITE_APP_BASE_API + 'user/check-2fa-otp',
    {},
    {
      params: {
        otp
      },
      headers: DEFAULT_HEADERS
    }
  );
};

export const disable2FA = (otp) => {
  return http.post(
    import.meta.env.VITE_APP_BASE_API + 'user/disable-2fa',
    {},
    {
      params: {
        otp
      },
      headers: DEFAULT_HEADERS
    }
  );
};

export const getDefaultImages = () => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'profile/default-images', {}, {
    headers: DEFAULT_HEADERS
  });
};

export const sendEmailVerification = (email) => {
  return http.post(import.meta.env.VITE_APP_BASE_API + 'user/send-verification', {}, {
    params: {
      email
    },
    headers: DEFAULT_HEADERS
  }
  );
};

export const verifyEmail = (email, code) => {
  return http.post(import.meta.env.VITE_APP_BASE_API + 'user/verify-email', {}, {
    params: {
      email,
      code
    },
    headers: DEFAULT_HEADERS
  });
};

export const getBillingDetails = () => {
  return http.get(
    generateApiEndpoint('profile/billing-details'),
    {
      headers: DEFAULT_HEADERS
    });
};

export const getInvoices = () => {
  return http.get(
    generateApiEndpoint('profile/invoices'),
    {
      headers: DEFAULT_HEADERS
    });
};

export const uploadFile = (file) => {
  return http.post(
    generateApiEndpoint('profile/upload/'),
    file,
    {
      headers: UPLOAD_FILE_HEADERS
    });
};

export const resendCode = (email) => {
  return http.post(import.meta.env.VITE_APP_BASE_API + 'user/resend-verification', {}, {
    params: {
      email: email
    },
    headers: DEFAULT_HEADERS
  });
};

export const getOnboardingStatus = () => {
  return http.get(import.meta.env.VITE_APP_BASE_API + 'onboarding', {}, {
    headers: DEFAULT_HEADERS
  });
};

export const updateOnboardingStatus = (data) => {
  return http.post(import.meta.env.VITE_APP_BASE_API + 'onboarding', data, {
    headers: DEFAULT_HEADERS
  });
};