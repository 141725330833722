import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Field, Radio, RadioGroup } from '@headlessui/react';

import DButton from '../Global/DButton';
import DModal from '../Global/DModal';
import AiAvatarIcon from '../Global/Icons/AiAvatarIcon';
import AiChatbotIcon from '../Global/Icons/AiChatbotIcon';
import AiVoiceIcon from '../Global/Icons/AiVoiceIcon';
import featureCheck, {
  checkTeamManagementPermission,
} from '@/helpers/tier/featureCheck';
import useToast from '@/hooks/useToast';
import { useUserStore } from '@/stores/user/userStore';
import amplitude from 'amplitude-js';
import checkEnv from '@/helpers/environmentCheck';
const modules = [
  {
    name: 'AI Chatbot',
    url_prefix: 'chatbot',
    Icon: AiChatbotIcon,
  },
  {
    name: 'Digital Persona',
    url_prefix: 'avatar',
    Icon: AiAvatarIcon,
    disabled: false,
    beta: true,
  },
  {
    name: 'AI Voice',
    url_prefix: 'voice',
    Icon: AiVoiceIcon,
    disabled: false,
    beta: true,
  },
];

const DModalCreateNew = ({
  open,
  onClose,
  handleOpenModalBetaVoice,
  handleOpenModalBetaAvatar,
}) => {
  let [selected, setSelected] = useState(modules[0]);
  const user = useUserStore((state) => state.user);
  const { addWarningToast } = useToast();
  const navigate = useNavigate();

  const handleCreate = () => {
    if (selected.url_prefix === 'chatbot') {
      if (user.total_ai_chatbots === user?.used_ai_chatbots) {
        addWarningToast({
          message:
            'You have reached the maximum number of chatbots for your current tier.',
        });
      }

      if (!checkTeamManagementPermission('create_chatbot')) {
        addWarningToast({
          message: 'You do not have permission to create a chatbot.',
        });
      }

      if (selected.url_prefix === 'chatbot') {
        if (checkEnv()) {
          amplitude.getInstance().logEvent('Create a Chatbot Clicked');
        }
      }
      navigate(`/${selected.url_prefix}/create`);
    } else if (selected.url_prefix === 'avatar') {
      handleOpenModalBetaAvatar();
    } else if (selected.url_prefix === 'voice') {
      handleOpenModalBetaVoice();
    }
    onClose();
  };

  return (
    <DModal
      isOpen={open}
      onClose={onClose}
      title="Create New"
      footer={
        <DButton
          variant="dark"
          fullWidth
          onClick={handleCreate}
          disabled={selected.disabled}
        >
          Create
        </DButton>
      }
    >
      <RadioGroup
        value={selected}
        onChange={setSelected}
        aria-label="Server size"
        className="flex md:flex-row flex-col md:justify-between justify-center"
      >
        {modules.map((module) => (
          <Field key={module.name} className="flex items-center gap-2">
            <Radio
              key={module.name}
              disabled={module.disabled}
              value={module}
              className=" transition flex relative md:flex-col items-center justify-center gap-2 data-[checked]:bg-grey-2 data-[checked]:border-grey-10 border border-white w-full md:w-[150px] h-20 md:h-32 cursor-pointer rounded-size0 p-size2 tracking-[-2%]"
            >
              {module.beta && (
                <div className="absolute top-2 right-2 bg-black text-white px-2 py-1 rounded-full text-xs">
                  Beta
                </div>
              )}
              <module.Icon
                className={`size-10 ${module.disabled ? 'opacity-50' : ''}`}
              />
              <span className={`w-max ${module.disabled ? 'opacity-50' : ''}`}>
                {module.name}
              </span>
            </Radio>
          </Field>
        ))}
      </RadioGroup>
    </DModal>
  );
};

export default DModalCreateNew;
