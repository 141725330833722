import { useEffect, useCallback } from 'react';
import { getNotifications } from '@/services/notification.service';
import useNotificationsStore from '@/stores/notifications/notifications';

const useNotificationsPolling = (interval = 30000) => {
  const addNotifications = useNotificationsStore((state) => state.addNotifications);

  const fetchNotifications = useCallback(async () => {
    try {
      const response = await getNotifications();
      if (response.status === 200) {
        addNotifications(response.data || []);
      }
    } catch (error) {
      console.error('Failed to fetch notifications:', error);
    }
  }, [addNotifications]);

  useEffect(() => {
    fetchNotifications();

    const pollingInterval = setInterval(fetchNotifications, interval);

    return () => clearInterval(pollingInterval);
  }, [fetchNotifications, interval]);
};

export default useNotificationsPolling;
