import { useNavigate } from 'react-router-dom';

import { useUserStore } from '@/stores/user/userStore';
import useTeamManagementStore from '@/stores/teamManagement/teamManagementStore';
import useLayoutStore from '@/stores/layout/layoutStore';
import { useConversationStore } from '@/stores/conversation/conversationStore';
import { useCustomizationStore } from '@/stores/customization/customizationStore';
import { useChatbotStore } from '@/stores/chatbot/chatbotStore';
import useNotificationsStore from '@/stores/notifications/notifications';
import useToastStore from '@/stores/toast/toastStore';

const useLogout = () => {
  const navigate = useNavigate();

  const removeAuthDetail = useUserStore((state) => state.removeAuthDetail);
  const resetUser = useUserStore((state) => state.reset);
  const resetTeamManagement = useTeamManagementStore((state) => state.reset);
  const resetLayout = useLayoutStore((state) => state.reset);
  const resetConversation = useConversationStore((state) => state.reset);
  const resetCustomization = useCustomizationStore((state) => state.reset);
  const resetChatbot = useChatbotStore((state) => state.reset);
  const resetNotifications = useNotificationsStore((state) => state.reset);
  const resetToast = useToastStore((state) => state.reset);

  return async () => {
    removeAuthDetail();
    resetUser();
    resetTeamManagement();
    resetLayout();
    resetConversation()
    resetCustomization();
    resetChatbot();
    resetNotifications();
    resetToast();
    localStorage.removeItem('theme');
    document.documentElement.classList.remove('dark');
    // Add navigation logic here if needed, e.g.:
    navigate('/log-in');
  };
};

export default useLogout;
