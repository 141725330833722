const DUserAvatar = ({ image, size }) => {
  let classSize = 'size-[40px]';

  if (size === 'sm') {
    classSize = 'size-[32px]';
  } else if (size === 'md') {
    classSize = 'size-[40px]';
  } else if (size === 'xl') {
    classSize = 'size-[80px]';
  }

  return (
    <div className={`${classSize} rounded-full flex items-center justify-center overflow-hidden`}>
      <img src={image} alt="" className="w-full" />
    </div>
  );
};
export default DUserAvatar;
