import { DEFAULT_HEADERS } from './constants.service';
import http from './http'

export const getNotifications = () => {
    return http.get(import.meta.env.VITE_APP_BASE_API + 'notifications', {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}


export const getNotification = (id) => {
    return http.get(import.meta.env.VITE_APP_BASE_API + 'notifications/' + id, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export const markNotificationAsRead = (id) => {
    return http.patch(import.meta.env.VITE_APP_BASE_API + 'notifications/' + id + '/seen', {}, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export const markAllAsRead = (type) => {
    return http.patch(import.meta.env.VITE_APP_BASE_API + 'notifications/seen-all', {}, {
        params: {
            type
        },
        headers: DEFAULT_HEADERS
    });
}

export const clearNotification = (id) => {
    return http.patch(import.meta.env.VITE_APP_BASE_API + 'notifications/' + id + '/clear', {}, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export const clearAllNotifications = () => {
    return http.patch(import.meta.env.VITE_APP_BASE_API + 'notifications/clear-all', {}, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}