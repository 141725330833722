const currentEnv = 'Production';

const checkEnv = () => {
  // return true for production
  if (window.location.hostname === 'app.dante-ai.com' && currentEnv === 'Production') {
    return true;
  } else if (window.location.hostname === 'staging.dante-ai.com' && currentEnv === 'Staging') {
    return true;
  } else if (
    window.location.hostname === 'd24dh0i3qzotw3.cloudfront.net' &&
    currentEnv === 'Staging'
  ) {
    return true;
  } else {
    return false;
  }
};

export default checkEnv;
