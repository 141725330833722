import React, { useEffect } from 'react';
import useNotificationsStore from '@/stores/notifications/notifications';
import ChevronRightIcon from '../../Global/Icons/ChevronRightIcon';
import NotificationIcon from '../../Global/Icons/NotificationIcon';
import * as notificationService from '@/services/notification.service';
import { DateTime } from 'luxon';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import OptionsHorizontalIcon from '@/components/Global/Icons/OptionsHorizontalIcon';

const DNotificationBlockUpdates = () => {
  const updates = useNotificationsStore((state) => state.notifications.updates);
  const markAsRead = useNotificationsStore((state) => state.markAsRead);
  const markAllAsRead = useNotificationsStore((state) => state.markAllAsRead);
  const clearNotification = useNotificationsStore(
    (state) => state.clearNotification
  );

  const handleMarkAsRead = async (id) => {
    try {
      const response = await notificationService.markNotificationAsRead(id); // Update on backend
      if (response.status === 200) {
        markAsRead(id);
      }
    } catch (error) {
      console.error('Failed to mark as read:', error);
    }
  };

  const handleClear = async (id) => {
    try {
      const response = await notificationService.clearNotification(id);
      if (response.status === 200) {
        clearNotification(id);
      }
    } catch (error) {
      console.error('Failed to clear:', error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const response = await notificationService.markAllAsRead('update');
      if (response.status === 200) {
        markAllAsRead();
      }
    } catch (error) {
      console.error('Failed to mark all as read:', error);
    }
  };

  useEffect(() => {
    return () => {
      handleMarkAllAsRead();
    };
  }, []);

  return (
    <div className="transition-all duration-300 flex flex-col gap-size2 py-size1 max-h-[250px] overflow-y-auto no-scrollbar">
      {updates.length > 0 ? (
        <div className="flex flex-col items-center gap-size1 divide-y">
          {updates.map((task) => (
            <div
              key={task.id}
              className="flex flex-col w-full items-start gap-size1 text-xs py-size1"
            >
              <header className="flex items-center justify-between w-full">
                <div className="flex items-center gap-size1">
                  <div
                    className={`size-size0 rounded-full bg-negative-200 ${
                      !task.seen ? 'opacity-100' : 'opacity-0'
                    }`}
                  ></div>
                  <p className="text-xs text-grey-20">
                    {DateTime.fromISO(task.date_created, {
                      zone: 'utc',
                    }).toRelative()}
                  </p>
                </div>
                <Menu>
                  <MenuButton>
                    <OptionsHorizontalIcon className="text-grey-50 size-size2" />
                  </MenuButton>
                  <MenuItems
                    transition
                    anchor="bottom-end"
                    className="origin-top-right border border-black/5 py-size1 px-size2 bg-white rounded-md shadow-md flex flex-col gap-size1 z-30 transition duration-100 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
                  >
                    {!task.seen && (
                      <MenuItem onClick={() => handleMarkAsRead(task.id)}>
                        <p className="text-xs cursor-pointer">Mark as read</p>
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => handleClear(task.id)}>
                      <p className="text-xs cursor-pointer">Clear</p>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </header>
              <div className="ml-size2">
                <p>{task.text}</p>
                {task.link && (
                  <a
                    className="text-xs text-grey-50 flex items-center gap-size0"
                    href={task.link}
                  >
                    {task.link_text} <ChevronRightIcon width={12} height={12} />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="min-h-36 flex flex-col items-center justify-center gap-size1">
          <div className="h-28 flex justify-center items-center">
            <NotificationIcon />
          </div>
          <div className="flex flex-col gap-size0 text-center">
            <p className="text-xs text-grey-50">No unread updates</p>
            <p className="text-2xs text-grey-50">
              New updates will be shown here
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DNotificationBlockUpdates;
