import { Transition, TransitionChild } from '@headlessui/react';
import clsx from 'clsx';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

const DTransition = ({
  children,
  type = 'fade',
  isChild,
  onInitialMount = false,
  unmount = false,
  show = false,
  className,
}) => {
  const contentRef = useRef(null);

  const time = 300;
  const delay = 100;
  const classesType = {
    [` relative overflow-hidden data-[enter]:duration-${time}   data-[enter]:data-[closed]:overflow-hidden data-[enter]:data-[closed]:-translate-y-1`]:
      type === 'collapse',
    [`data-[leave]:delay-${delay} data-[leave]:duration-${time} data-[leave]:data-[closed]:-translate-y-1 data-[leave]:overflow-hidden`]:
      type === 'collapse',
    [`!max-h-[${contentRef.current?.clientHeight}px]`]:
      type === 'collapse' && show,
    'data-[closed]:opacity-0 data-[enter]:delay-100 duration-300':
      type === 'fade',
    'duration-300 data-[closed]:-translate-x-full top-1/2 -translate-y-1/2 absolute w-full':
      type === 'slide',
  };

  return (
    <>
      <Transition show={show} appear={true} unmount={unmount}>
        <div
          className={clsx(
            'transition-all data-[closed]:opacity-0',
            classesType,
            className
          )}
          ref={contentRef}
        >
          {children}
        </div>
      </Transition>
    </>
  );
};

export default DTransition;
