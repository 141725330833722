import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import React, { useRef } from 'react';

/**
 * DTooltip component displays a tooltip with specified content when hovering over its child element.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The element that triggers the tooltip on hover.
 * @param {string} props.content - The text content displayed inside the tooltip.
 * @param {string} [props.position='top center'] - The tooltip's position relative to the trigger element.
 * Supports 'top start', 'top center', 'top end', 'right start', 'right center', 'right end', 'bottom start', 'bottom center', 'bottom end', 'left start', 'left center', 'left end'.
 *
 * @returns {JSX.Element} The rendered tooltip component.
 */
const DTooltip = ({ children, content, position = 'top center' }) => {
  const buttonRef = useRef(null);

  return (
    <Popover className={'flex items-center'}>
      <PopoverButton
        ref={buttonRef}
        onMouseEnter={() => buttonRef.current?.click()}
        onMouseLeave={() => buttonRef.current?.click()}
      >
        {children}
      </PopoverButton>
      <PopoverPanel
        transition
        anchor={position}
        className="bg-gray-800 text-white text-xs rounded px-2 py-1 whitespace-nowrap shadow-sm transition-all duration-300 data-[closed]:scale-95 data-[closed]:opacity-0 !max-w-[400px] text-wrap"
      >
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </PopoverPanel>
    </Popover>
  );
};

export default DTooltip;
