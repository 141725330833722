import React from 'react';
import { DateTime } from 'luxon';

import DModalImage from '../Global/DModalImage';
import ModalWelcomeImage from '@/assets/modal/welcome.png';
import useLayoutStore from '@/stores/layout/layoutStore';

const DModalWelcome = () => {
  const today = DateTime.now();
  const lastDayOfTrial = today.plus({ days: 7 }).toFormat('MMMM dd');

  const { setWelcomeModal, welcomeModal } = useLayoutStore();

  const handleClose = () => {
    setWelcomeModal({ isOpen: false });
  };

  return (
    <DModalImage
      open={welcomeModal.isOpen}
      onClose={handleClose}
      hideCloseButton
      image={ModalWelcomeImage}
      text="Welcome to AI simplicity!"
      description={`You have started your free 7-day trial! Explore Dante AI until ${lastDayOfTrial} or upgrade to a paid plan at any time.`}
      firstBtnText="Show me around"
      secondBtnText="Great, let me explore"
      onFirstBtnClick={handleClose}
      onSecondBtnClick={handleClose}
    />
  );
};

export default DModalWelcome;
