import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import DButton from '../DButton';

const DModalImage = ({
  open,
  onClose,
  image,
  text,
  description,
  firstBtnText,
  secondBtnText,
  onFirstBtnClick,
  onSecondBtnClick,
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 duration-300 bg-white/90 data-[closed]:opacity-0 data-[leave]:delay-200"
      />
      <div className="fixed inset-0 flex w-screen items-center justify-center">
        <DialogPanel
          transition
          className="w-11/12 md:w-full md:max-w-[500px] max-h-[80vh] bg-white rounded-size1 flex flex-col gap-size5 border border-grey-5 transition data-[enter]:delay-200 duration-300 data-[closed]:-translate-y-20 data-[closed]:opacity-0"
        >
          <img src={image} alt="image" className="max-w-[520px] w-full" />
          <div className="flex flex-col gap-size6 p-size6">
            <div className="flex flex-col gap-size1 items-center">
              <p className="text-2xl font-medium tracking-tight">{text}</p>
              {description && (
                <p className="text-sm text-grey-75 font-light text-center">
                  {description}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-size1">
              <DButton
                variant="dark"
                size="md"
                onClick={onFirstBtnClick}
                fullWidth
              >
                {firstBtnText}
              </DButton>
              <DButton
                variant="outlined"
                size="md"
                onClick={onSecondBtnClick}
                fullWidth
              >
                {secondBtnText}
              </DButton>
            </div>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default DModalImage;
