import React, { useEffect, useState } from 'react';

import DProgressBar from '@/components/Global/DProgressBar';
import AddIcon from '@/components/Global/Icons/AddIcon';
import MobileNavListItem from '@/components/Global/Icons/MobileNavListItem';
import MainNav from '@/components/MainNav';
import useLayoutStore from '@/stores/layout/layoutStore';
import { Dialog, DialogPanel } from '@headlessui/react';
import DToastContainer from '@/components/DToast/DToastContainer';
import DModalCreateNew from '@/components/DModalCreateNew';

/**
 * This layout component is used to display the content of the children
 * when the device screen size is smaller than the `sm` breakpoint.
 */
const LayoutMobile = ({ children, title, progressBar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const setSidebarOpen = useLayoutStore((state) => state.setSidebarOpen);
  const [openModalCreateNew, setOpenModalCreateNew] = useState(false);

  const handleToggleNav = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSidebarOpen(true);
  }, []);

  return (
    <main className="d-h-screen flex flex-row gap-size5 p-size2">
      <div className="w-full h-full flex flex-col gap-size5">
        <header>
          <div className="flex flex-col gap-size2">
            <div className="flex justify-between items-center relative min-h-[40px] ">
              {title ? (
                <h1 className="text-lg md:text-xl">{title}</h1>
              ) : (
                <div />
              )}
              <div className="h-[40px] flex gap-size2">
                <button
                  className="size-10 bg-purple-200 rounded-size0 text-white flex items-center justify-center"
                  onClick={() => setOpenModalCreateNew(true)}
                >
                  <AddIcon />
                </button>
                <button
                  onClick={handleToggleNav}
                  className="size-10 bg-transparent border border-grey-5 rounded-size0 text-black flex items-center justify-center"
                >
                  <MobileNavListItem />
                </button>
              </div>
            </div>
            {progressBar && progressBar.length > 0 && (
              <DProgressBar steps={progressBar} />
            )}
          </div>
          <DToastContainer />
        </header>
        <div className="flex flex-col grow">{children}</div>
      </div>

      <Dialog open={isOpen} onClose={setIsOpen} className="relative z-10">
        <div className="fixed inset-0 overflow-hidden">
          <DialogPanel
            transition
            className="pointer-events-auto relative w-screen transform transition duration-150 ease-in-out data-[closed]:translate-x-full sm:duration-700 flex items-start bg-white h-full pt-2 pl-size2"
          >
            <MainNav hideCollapsedButton closeMobileNav={handleToggleNav} />
          </DialogPanel>
        </div>
      </Dialog>
      <DModalCreateNew
        open={openModalCreateNew}
        onClose={() => setOpenModalCreateNew(false)}
      />
    </main>
  );
};

export default LayoutMobile;
