import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import { GoogleOAuthProvider } from '@react-oauth/google';import amplitude from 'amplitude-js';
import App from './App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};

amplitude.getInstance().init(import.meta.env.VITE_APP_AMPLITUDE_API_KEY);


root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);
