import { useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router-dom';

import refreshAccessToken from '@/application/auth/refreshAccessToken';
import { useUserStore } from '@/stores/user/userStore';

const PrivateRoute = ({ permission, children }) => {
  const { auth } = useUserStore();
  const [checkingSession, setCheckingSession] = useState(true);
  const shouldRequest = useRef(false);

  const validateToken = async () => {
    setCheckingSession(true);
    if (auth.access_token) {
      await refreshAccessToken();
    }
    setCheckingSession(false);
  };

  useEffect(() => {
    if (!shouldRequest.current) {
      validateToken();
      shouldRequest.current = true;
    }
  }, []);

  if (checkingSession) {
    return null;
  }

  return auth.access_token ? children : <Navigate to="/log-in" replace />;
};

export default PrivateRoute;
