import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import BookIcon from '../Global/Icons/BookIcon';

const HeaderLearningHub = () => {
  return (
    <Menu>
      {({ open }) => (
        <>
          {open && (
            <div className="transition-all fixed inset-0 bg-white/90 md:hidden z-20"></div>
          )}
          {open && (
            <div className="transition-all fixed inset-0 top-20 bg-white/90 md:hidden z-20"></div>
          )}
          <MenuButton className="inline-flex items-center justify-center gap-2 rounded-size0 z-30   font-semibold text-black  focus:outline-none data-[hover]:bg-grey-2 data-[open]:bg-grey-5 data-[focus]:outline-1 data-[focus]:outline-black size-[40px] inset-1">
            <BookIcon />
          </MenuButton>

          <MenuItems
            transition
            modal={false}
            anchor="bottom"
            className="w-full z-20 sm:!max-w-[376px] origin-top-right h-[90%] p-size1 rounded-size0 border border-black/5 bg-white text-black transition duration-500 ease-out [--anchor-gap:16px] focus:outline-none data-[closed]:scale-55 data-[closed]:opacity-0"
          >
            <iframe
              src="https://dante--ai.webflow.io/dev/guides-iframe"
              width="100%"
              height="100%"
            ></iframe>
          </MenuItems>
        </>
      )}
    </Menu>
  );
};

export default HeaderLearningHub;
